<template>
  <Modal class="dialog-log" @click="close">
    <div class="dialog-log-wrapper" @click="close">
      <!--
      <div
        class="dialog-log-items"
        v-for="item in history"
        :key="item.key"
        :style="
          stories[item.key].color
            ? {
                backgroundColor: stories[item.key].color.bg,
                color: stories[item.key].color.fg,
              }
            : null
        "
      >
      -->
      <div v-for="item in history" :key="item.key" class="dialog-log-items">
        <p class="from" v-html="stories[item.key].from"></p>
        <div class="message" v-html="stories[item.key].message"></div>
      </div>
    </div>
  </Modal>
</template>

<script>
import modal from '@/mixins/modal';
export default {
  name: 'DialogLog',
  components: {
    Modal: () => import('@/components/Modal.vue')
  },
  mixins: [modal],
  props: {
    stories: {
      type: Object,
      required: true
    },
    history: {
      type: Array,
      required: true
    },
    onClose: { type: Function, required: true }
  },
  data() {
    return {
      visible: false,
      result: false
    };
  },
  methods: {}
};
</script>

<style scoped>
.dialog-log-wrapper {
  margin: 1em;
  overflow: auto;
  max-height: 90vh;
}
.dialog-log-items {
  padding: 0.25em;
  margin: 0.25em;
  /*border-radius: 0.5em;*/
  border-bottom: 1px solid #ccc;
}
.dialog-log-items:last-child {
  border-bottom: none;
}
.dialog-log-items .from {
  line-height: 1.5em;
  font-size: 0.75em;
}
.dialog-log-items .from:after {
  content: ':';
}
.dialog-log-items .message {
  margin-left: 0.5em;
  line-height: 1.5em;
}

::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}
::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 0.5em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 1em;
  border: 0.25em solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #444;
}
</style>
