<template>
  <div :id="type" class="debug">
    <main class="main">
      <input v-model="id" type="text" placeholder="id" />
      <input v-model="status" type="number" placeholder="status" />
      <button @click="mount">load</button>
      <div id="loading" :class="loading ? 'show' : null">
        <div v-if="loading" class="meter_wrapper">
          <div class="meter_header">
            <p>
              <ruby><rb>読込中</rb><rt>よみこみちゅう</rt></ruby
              >...
            </p>
            <p class="detail">
              <ruby><rb>読込中</rb><rt>よみこみちゅう</rt></ruby
              >のデータ({{ loading.image.id }}): {{ loading.image.percent }}%
            </p>
          </div>
          <div class="meter">
            <p :style="'width:' + loading.percent + '%'"></p>
          </div>
        </div>
      </div>
      <template v-for="(story, story_id) in stories">
        <h2 :key="'h2' + story_id">{{ story_id }}</h2>
        <button
          v-if="typeof story.next === 'object'"
          :key="'dialog' + story_id"
          @click="nextFrom(story_id)"
        >
          dialog
        </button>
        <div :key="'container' + story_id" class="container">
          <div :key="'game' + story_id" class="game">
            <div :key="story_id">
              <Room
                v-if="story.type === 'narration'"
                :key="'narration' + story_id"
                :data="story"
                @next="next"
                @log="log"
              />
              <Room
                v-if="story.type === 'talk'"
                :key="'talk' + story_id"
                :data="story"
                @next="next"
                @log="log"
              />
              <Chat
                v-if="story.type === 'chat'"
                :key="'chat' + story_id"
                :history="history"
                :stories="stories"
                @next="next"
              />
              <Info
                v-if="story.type === 'result'"
                :key="'result' + story_id"
                :data="story"
                @next="next"
              />
              <Info
                v-if="story.type === 'info'"
                :key="'info' + story_id"
                :data="story"
                @next="next"
              />
            </div>
          </div>
        </div>
      </template>
    </main>
  </div>
</template>

<script>
import { hsv2rgb } from '@/service/utils.js';
import {
  formatAll,
  formatAllasLine,
  formatAsSpeechText
} from '@/service/formatter.js';
const static_resources = {
  hint: require('./img/info/mican_hint.png'),
  clear: require('./img/info/mican_clear.png')
};
import Dialog from '@/components/Dialog.vue';
import DialogLog from './components/DialogLog';
import dialogs from '@/mixins/dialogs';

export default {
  name: 'Game',
  components: {
    Room: () => import('./components/Room.vue'),
    Chat: () => import('./components/Chat.vue'),
    Info: () => import('./components/Info.vue')
  },
  mixins: [dialogs],
  props: {},
  data() {
    return {
      id: '',
      status: 0,
      front: 0, // front id for double buffer
      current: ['start', 'start'], // current scene id for double buffer
      loading: null,
      title: '',
      type: '',
      history: [],
      resources: {},
      colors: {},
      stories: { start: { type: null } },
      canSpeech: false,
      doSpeech: false,
      speech: null
    };
  },
  computed: {
    prev_id() {
      return Number(this.current[this.front]) - 1;
    },
    next_id() {
      return Number(this.current[this.front]) + 1;
    }
  },

  beforeDestroy() {
    window.removeEventListener('keypress', this.keyInputHandler);
  },
  methods: {
    nextFrom(sid) {
      this.current[this.front] = sid;
      this.next();
    },
    async mount() {
      if (!this.id) {
        return;
      }

      // init loading screen data
      this.loading = {
        loaded: 0,
        length: 0,
        percent: 0,
        image: { id: '', url: '', loaded: 0, length: 0, percent: 0 }
      };

      // load raw data from api
      let data = await this.$store.dispatch('api/getRoom', {
        id: this.id,
        status: this.status
      });
      if (this.status === undefined) {
        this.status === 0;
      }
      this.title = data.name;
      // pre data translation for experience
      if (data.experience) {
        this.type = 'experience';
        data = data.experience;
      }
      // pre data translation for test
      if (data.test) {
        this.type = 'test';
        data = data.test;
      }
      // pre data translation for lecture
      if (data.lecture) {
        this.type = 'lecture';
        data = data.lecture;
        // translate scenes object from array
        const scenes = {};
        for (let i = 0; i < data.scenes.length; i++) {
          const item = data.scenes[i];
          scenes[i] = item;
          item.next = String(i + 1);
        }
        scenes[data.scenes.length - 1].next = 'end';
        data.scenes = scenes;
        data.startScene = '0';
      }
      // set data into stories
      const stories = data.scenes;
      stories.start = {
        type: null
      };

      // init loading info
      this.loading.length =
        (data.sprites ? Object.keys(data.sprites).length : 0) +
        (data.backgrounds ? Object.keys(data.backgrounds).length : 0) +
        (data.images ? Object.keys(data.images).length : 0) +
        (static_resources.images
          ? Object.keys(static_resources.images).length
          : 0);
      // resource loading
      await this.loadResources(data.sprites);
      await this.loadResources(data.backgrounds);
      await this.loadResources(data.images);
      await this.loadResources(static_resources, true);

      // init colors
      if (data.colors) {
        this.colors = data.colors;
      } else {
        this.colors = {};
        if (data.sprites) {
          const step = 1.0 / Object.keys(data.sprites).length;
          let h = 0;
          for (let s in data.sprites) {
            this.colors[s] = {
              bg: hsv2rgb.hex(h, 0.3, 1) + 'fa',
              fg: hsv2rgb.hex(h, 0.5, 0.2)
            };
            h += step;
          }
        }
      }

      // pre-proc for each scene(story) object
      for (let story_id in stories) {
        const story = stories[story_id];
        story.id = story_id;
        if (!story.preproc) {
          if (story.options) {
            // translate old scene object with options into new option scene format
            console.log(
              "warning: options are old format. options array should be put as 'next'."
            );
            const prev = Object.values(stories).find(item => {
              return item.next === story_id;
            });
            prev.qid = story.qui ? story.qui : prev.qid;
            prev.next = story.options.map(option => {
              option.text = formatAllasLine.format(option.text);
              option.value = option;
              return option;
            });
          } else {
            if (story.type === 'result') {
              // insert result scene
              switch (this.type) {
                case 'test':
                  if (story.result) {
                    story.image = story.image ? story.image : 'clear';
                    story.title = story.title ? story.title : '[合格:ごうかく]';
                  } else {
                    story.image = story.image ? story.image : 'hint';
                    story.title = story.title
                      ? story.title
                      : '[不合格:ふごうかく]';
                  }
                  break;
                case 'experience':
                case 'lecture':
              }
            }
            // format text for options
            if (typeof story.next === 'object') {
              //new format
              for (let option of story.next) {
                option.text = formatAllasLine.format(option.text);
                option.value = option;
              }
            }

            // init bg
            if (story.bg) {
              // set bg as blob url
              story.bg = this.getResource(story.bg);
            }
            // init main char.
            if (story.main && story.main.name) {
              // set unit sprite as blob url
              story.main.image = this.getResource(
                story.main.name,
                story.main.skin
              );
              // set main as active for default
              story.main.isActive = true;
            }

            // init sub char.
            if (story.sub && story.sub.name) {
              // set unit sprite as blob url
              story.sub.image = this.getResource(
                story.sub.name,
                story.sub.skin
              );
              // set sub active flag
              if (story.from === story.sub.name) {
                story.sub.isActive = true;
                if (story.main) {
                  story.main.isActive = false;
                }
              }
            }
            // init main char positon
            if (story.main) {
              story.main.position = story.sub ? 'left' : 'center';
            }
            // init sub char positon
            if (story.sub) {
              story.sub.position = story.main ? 'right' : 'center';
            }
            // set clip image as blob url
            if (story.image) {
              story.image = this.getResource(story.image);
            }
            // set color
            const color = this.colors[story.from];
            if (color) {
              story.color = color;
            }
            // format texts
            story.from = formatAllasLine.format(story.from);
            story.title = formatAllasLine.format(story.title);
            story.speech = formatAsSpeechText.format(story.message);
            story.message = formatAll.format(story.message);
          }
          story.preproc = true;
        }
      }
      this.stories = stories;
      this.canSpeech = window.speechSynthesis;
      if (this.canSpeech) {
        this.speech = new SpeechSynthesisUtterance();
        this.speech.lang = 'ja-JP';
        this.speech.rate = 1.0;
        this.speech.pitch = 1.5;
        this.speech.volume = 1.0;
      }
      this.setNext(data.startScene);
      this.$emit('load', this.type, this.title);
      // init key event
      window.addEventListener('keypress', this.keyInputHandler);
      this.loading = false;
    },

    async log() {
      await this.$confirm({
        component: DialogLog,
        props: {
          stories: this.stories,
          history: this.history
        }
      });
    },
    keyInputHandler(evt) {
      switch (evt.keyCode) {
        case 13:
        case 32:
          if (!document.querySelector('.modal')) {
            this.next();
          }
          break;
      }
    },
    async next() {
      const current = this.stories[this.current[this.front]];
      if (typeof current.next === 'object') {
        const result = await this.$confirm({
          component: Dialog,
          props: {
            type: 'vertical',
            message: current.message,
            options: current.next
          }
        });
        console.log(
          this.type === 'test',
          result.correct == false,
          current.qid !== undefined
        );
        if (
          this.type === 'test' &&
          result.correct == false &&
          current.qid !== undefined
        ) {
          const payload = {
            roomId: this.id,
            questionId: current.qid
          };
          console.log('api/setTestData', payload);
          await this.$store.dispatch('api/setTestData', payload);
        }
        this.setNext(result.next);
      } else if (current.next === 'end') {
        await this.$store.dispatch('api/setRoomUserData', {
          id: this.id,
          data: {
            result: current.result,
            screen: this.type === 'lecture' ? 1 : this.type === 'test' ? 2 : 0
          }
        });
        this.$router.push({ name: 'LiteracyMap' }).catch(() => {});
      } else {
        // noraml transition
        this.setNext(current.next);
      }
    },
    setNext(next, ans) {
      if (next === this.current[this.front]) {
        return;
      }
      if (ans) {
        this.history[this.history.length].ans = ans;
      }
      this.history.push({ key: next });
      this.front = this.front ? 0 : 1;
      this.current[this.front] = next;
      this.exec_speech();
    },
    exec_speech() {
      if (this.doSpeech && this.canSpeech) {
        this.speech.text = this.stories[this.current[this.front]].speech;
        speechSynthesis.speak(this.speech);
      }
    },
    toggleSpeech() {
      this.doSpeech = !this.doSpeech;
      this.exec_speech();
    },
    // loadResources has a lot of side effects to this.loading object
    async loadResources(resources, isUrl) {
      if (!resources) {
        return;
      }
      for (let resource_id in resources) {
        const resource = resources[resource_id];
        if (typeof resource === 'string') {
          this.resources[resource_id] = { default: resource };
        } else {
          this.resources[resource_id] = Object.assign({}, resource);
        }
        const loaded_resource = this.resources[resource_id];
        for (let skin_id in loaded_resource) {
          this.loading.image.id = resource_id + '-' + skin_id;
          if (!isUrl) {
            await this.$store
              .dispatch('api/getImageStream', {
                url: loaded_resource[skin_id],
                onRead: (loaded, length, url) => {
                  this.loading.image.url = url;
                  this.loading.image.loaded = loaded;
                  this.loading.image.length = length;
                  this.loading.image.percent = Math.floor(
                    (100 * loaded) / length
                  );
                }
              })
              .then(data => data.blob())
              .then(data => {
                loaded_resource[skin_id] = URL.createObjectURL(data);
              })
              .catch(error => {
                console.error(error);
              });
          }
        }
        this.loading.loaded++;
        this.loading.percent = Math.floor(
          (100 * this.loading.loaded) / this.loading.length
        );
      }
    },
    getResource(name, skin) {
      const _skin = skin ? skin : 'default';
      return this.resources[name][_skin];
    }
  }
};
</script>
<style scoped>
/*
.game .game-nav .btn-back a {
  font-size: 1.2rem !important;
}
*/
.debug main {
  overflow: auto;
  height: auto !important;
}

@media screen and (max-width: 1000px) {
  .debug main {
    height: 100vh !important;
  }
}
.debug h2 {
  margin: 0.5em;
}
.debug .game > div {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: #ffffff;
}

@media screen and (max-width: 1000px) {
  .debug .game > div {
    width: 100%;
    height: 100%;
  }
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  color: #000;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 200%;
  transition: 0.3s;
  z-index: 10000;
  opacity: 0;
  pointer-events: none;
}
.meter_wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 66%;
}
.meter_header {
  display: flex;
  align-items: baseline;
}
.meter {
  width: 100%;
  height: 1em;
  background-color: #444;
  border-radius: 1em;
  padding: 0.25em;
}
.meter p {
  height: 0.5em;
  background-color: #f80;
  display: block;
  max-width: 100%;
  border-radius: 1em;
}
.detail {
  font-size: 66%;
  font-weight: normal;
  flex: 1;
  text-align: right;
}

#loading.show {
  opacity: 1;
  pointer-events: all;
}
#loading::before {
  position: fixed;
  content: '';
  background-image: url(./img/header/bg-mican.svg);
  background-size: 50% auto;
  background-repeat: no-repeat;
  background-position: 90% 90%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: 10s linear 0s 1 running easein;
}
@keyframes easein {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(2);
  }
}

/* ToDo: decide pager layout spec. */
@media only screen and (min-width: 1001px) {
  #lecture .pager {
    width: calc(100% - 30px);
  }
}
</style>
